import '../styles/style.scss'
import '../index.html'
import '../films/cinq.html'
import './menu.js'
import './header.js'
import './film.js'
import Plyr from 'plyr';

Plyr.setup('#player')


// Menu functionality
var p = window.location.pathname;

 if (p.length === 0 || p === "/" || p.match(/^\/?index/)) {
    document.getElementsByClassName('menu')[0].getElementsByTagName('h5')[0].addEventListener("click", () => {
        window.scrollTo(0, 0);
    })

    document.getElementsByClassName('menu')[0].getElementsByTagName('h5')[1].addEventListener("click", () => {
        document.getElementsByClassName('movie')[0].scrollIntoView(true)
        window.scrollBy(0, -70);
    })

    document.getElementsByClassName('menu')[0].getElementsByTagName('h5')[2].addEventListener("click", () => {
        document.getElementsByClassName('album')[0].scrollIntoView(true)
    })
}