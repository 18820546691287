let menuPhone = document.createElement('menu')

menuPhone.innerHTML = `
<svg src="/assets/menu.svg">
    <line x1="0" y1="3" x2="100" y2="3" stroke="#fff" stroke-width="1"/>
    <line x1="0" y1="23" x2="100" y2="23" stroke="#fff" stroke-width="1"/>
    <line x1="0" y1="43" x2="100" y2="43" stroke="#fff" stroke-width="1"/>
</svg>
`

/*document.body.appendChild(menuPhone)*/

// Menu PC
var p = window.location.pathname;

 if (p.length === 0 || p === "/" || p.match(/^\/?index/)) {
    let menuDesktop = document.createElement('div')
    menuDesktop.classList.add('menu')

    menuDesktop.innerHTML = `
        <h5>About</h5>
        <h5>Film</h5>
        <h5>Music</h5>
    `

    document.getElementsByClassName('wrapper')[0].appendChild(menuDesktop)
 }